import React from "react"
import Layout from "../components/Layout"
import "./style.scss"
import { chunkArray } from "../common/utils"
import { getBlogs } from "../common/data"

/* eslint-disable jsx-a11y/anchor-is-valid */
// Temporary disable rule for a href links
const data = getBlogs()
const Body = () => (
  <Layout>
    <section className="hero is-light">
      <div className="hero-body showcase-container">
        <div className="container has-text-centered">
          <span className="title is-size-1-desktop is-size-3-mobile has-text-weight-semibold main-title">
            Blog
          </span>
        </div>
      </div>
    </section>
    <section className="section is-desktop">
      <Grid data={data} cols={2} />
    </section>
  </Layout>
)

const Grid = ({ data, cols }) =>
  data && data instanceof Array ? (
    <div className="container blog-grid">
      {chunkArray(data, cols).map((columns, i) => (
        <Row key={i} index={i} columns={columns} />
      ))}
    </div>
  ) : null

const Row = ({ columns, index }) => (
  <div className="columns">
    {columns &&
      columns.map((tileData, i) => (
        <BlogCard key={index + "-" + (tileData.id || i)} {...tileData} />
      ))}
  </div>
)

const BlogCard = ({ title, image, author, publishingDate, description }) => (
  <div className="card blog-card is-desktop">
    <div className="card-image">
      <figure className="image is-2by1">
        <img
          src={image || "https://bulma.io/images/placeholders/1280x960.png"}
          alt="Blog"
        />
      </figure>
    </div>
    <div className="card-content">
      <div className="media">
        <div className="media-content">
          <p className="title is-4">{title}</p>
          <p className="subtitle is-6">
            <time dateTime="2016-1-1">{publishingDate}</time> |{" "}
            <span>{author}</span>
          </p>
        </div>
      </div>

      <div className="content">
        {description}
        <br />
      </div>
    </div>
  </div>
)

export default () => <Body />
