import { formatDate } from "./utils";

const date = formatDate(new Date());
const blogItem = {
  image: "",
  title: "This is my Blog Title",
  date,
  author: "Author Name",
  description:
    "This is a short description of my blog, it isn't that long and not that shortbut instead it is the perfect length. No one will really read the details here but it might make additional people click",
};

export const getBlogs = () => Array(9).fill(blogItem);
